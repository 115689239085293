<template>
  <div>
    <el-input-number v-model="jsonSpaceNum"
                     @change="onJsonSpaceNumChange">
    </el-input-number>
    <h1>This is an about page</h1>
  </div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      jsonSpaceNum: 2
    }
  },
  methods: {
    onJsonSpaceNumChange () {
    }
  }
}
</script>
